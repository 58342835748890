import {Controller} from "stimulus"

export default class extends Controller {

  async getTranslatedWords(title, text, confirmButton, cancelButton) {
    const result = await Weglot.translate({
      words: [
        { t: 1, w: title },
        { t: 1, w: text },
        { t: 1, w: confirmButton },
        { t: 1, w: cancelButton },
      ],
      languageTo: Weglot.getCurrentLang(),
    });
    return result;
  }

  newTranslation(event){
    const locale = event.currentTarget.value
    const flag_code = this.flagCode(locale)
    const field = event.currentTarget.dataset.field
    const template = event.currentTarget.dataset.template
    const translation = event.currentTarget.querySelector('option:checked').innerText
    const translation_downcase = translation.toLowerCase()

    let is_title = field === "title"
    let titleContent = `<input type="text" name="${template}[translation_keys][title][${locale}]" id="title-${locale}" class="form-control">`
    let descriptionContent = `<div class="tooltip-input">
                                        <div id="${locale}-snow-editor" class="snow-editor" data-controller="general--quill capture-emails--translations" data-action="keyup->capture-emails--translations#updateQuill" data-locale="${locale}"></div>
                                        <textarea type="text" name="${template}[translation_keys][description][${locale}]" id="description-${locale}" class="form-control hidden"></textarea>
                                     </div>`
    let html = `<div class="col-md-6 translation-${locale}">
                        <div class="form-group text-left m-b-25">
                          <label for="${field}-${translation_downcase}">
                            <span class="flag-icon flag-icon-${flag_code}"></span>
                            ${translation}
                          </label>
                          <span class="remove-translation" data-controller="capture-emails--translations" data-action="click->capture-emails--translations#removeTranslation" data-locale="${locale}" data-translation="${translation}">
                            <i data-feather="x" width="14" height="14"></i>
                          </span>
                          ${ is_title ? titleContent : descriptionContent }
                        </div>
                      </div>`
    if (is_title)
      $("#title-translations").append(html)
    else
      $("#description-translations").append(html)

    feather.replace()
    event.currentTarget.value = ""
    document.querySelector(`option[value=${locale}]`).remove();
  }

  flagCode(locale){
    if ( ['zh-CN', 'ja', 'ko', 'uk'].includes(locale) ) {
      switch (locale) {
        case 'zh-CN':
          return "cn"
        case 'ja':
          return "jp"
        case 'ko':
          return "kr"
        case 'uk':
          return "ua"
      }
    } else {
      return locale
    }
  }

  async removeTranslation(event){
    const button = event.currentTarget;
    const locale = button.dataset.locale
    const translation = button.dataset.translation
    const element = document.querySelector('.add-translation');

    let title = 'Are you sure?';
    let text  = 'Your are about to remove this language';
    let confirmButton = 'Yes, remove language!';
    let cancelButton  = 'Cancel';

    try {
        const translatedWords = await this.getTranslatedWords(title, text, confirmButton, cancelButton);

        if (translatedWords.length === 4) {
          [title, text, confirmButton, cancelButton] = translatedWords;
        }

      swal({
        title: title,
        text: text,
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: confirmButton,
        cancelButtonText: cancelButton,
        confirmButtonClass: 'btn btn-lg btn-danger waves-effect waves-light m-t-20',
        cancelButtonClass: 'btn btn-lg btn-transparent waves-effect waves-light m-l-10 m-t-20',
        buttonsStyling: false
      }).then(function () {
        button.parentElement.parentElement.remove();
        element.append(`<option value="${locale}">${translation}</option>`);
      }, function (dismiss) {
        if (dismiss === 'cancel') {
          // window.location.href = link;
        }
      })
    } catch (e) {}
  }

  updateQuill(event){
    const locale = event.currentTarget.getAttribute("data-locale")
    document.querySelector(`#description-${locale}`).value = document.querySelector(`#${locale}-snow-editor .ql-editor`).innerHTML
  }

}
