import { Controller } from "stimulus";

export default class extends Controller {

  connect() {
    let inputRadio = document.querySelector('.btn.setup-card-btn input[type=radio]:checked')
    if(!inputRadio) {
      inputRadio = document.querySelector('.btn.setup-card-btn input[type=radio]')
      inputRadio.setAttribute("checked", "checked")
    }
    inputRadio.parentElement.classList.add("selected")
  }

  updatePosition(event){
    event.stopPropagation();
    document.querySelectorAll('.btn.setup-card-btn').forEach(button => {
      const inputRadio = button.querySelector('input[type=radio]')
      if (button === event.currentTarget) {
        button.classList.add("selected")
        inputRadio.setAttribute("checked", "checked")
      } else {
        button.classList.remove("selected")
        inputRadio.removeAttribute("checked")
      }
    })
  }

}
