import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [
    "container",
    "buttonContainer",
    "mainButtonGroup"
  ]

  showUrl(event){
    let parentElement = event.currentTarget.parentElement;
    parentElement.previousElementSibling.classList.remove('hidden');
    parentElement.classList.add('hidden');
  }

  showText(event){
    let parentElement = event.currentTarget.parentElement;
    parentElement.nextElementSibling.classList.remove('hidden');
    parentElement.classList.add('hidden');
  }

  addButton() {
    let buttonsCount = this.mainButtonGroupTargets.length + 1
    let newButtonGroup = `<div class="form-group btn-form-group" data-target="capture-emails--buttons.mainButtonGroup">
                                      <label>Button ${buttonsCount}</label>
                                      <div class="input-group-flex">
                                        <div class="input-group hidden">
                                          <button type="button" class="btn type_button_left" data-action="click->capture-emails--buttons#showText">
                                            <i data-feather="type" class="icon" width="20" height="20"></i>
                                          </button>
                                          <input class="form-control" type="text" value="" name="capture_email_one[buttons[][button_url]]" data-button-id="button-${buttonsCount}">
                                        </div>
                                        <div class="input-group">
                                          <input class="form-control" type="text" value="Button" name="capture_email_one[buttons[][button_text]]" data-action="input->capture-emails--buttons#buttonUpdate" data-button-id="button-${buttonsCount}">
                                          <button type="button" class="btn link_button_right" data-action="click->capture-emails--buttons#showUrl">
                                            <i data-feather="link" class="icon" width="20" height="20"></i>
                                          </button>
                                        </div>
                                        <button type="button" class="btn btn-remove" data-action="click->capture-emails--buttons#removeButton">
                                          <i data-feather="x" class="icon" width="20" height="20"></i>
                                        </button>
                                      </div>
                                    </div>`

    let linkButton = document.createElement("a")
    linkButton.classList.add("bb-btn-link", `button-${buttonsCount}`);
    linkButton.innerText = "Button"
    this.containerTarget.insertAdjacentHTML("beforeend", newButtonGroup);
    feather.replace()
    this.buttonContainerTarget.append(linkButton);
    this.updateLabel();
  }

  buttonUpdate(event) {
    let { buttonId } = event.currentTarget.dataset;
    this.buttonContainerTarget.querySelector(`a.bb-btn-link.${buttonId}`).text = event.currentTarget.value
  }

  async removeButton(event){
    let controller = this
    let button = event.currentTarget;
    let buttonParent = event.currentTarget.parentElement;

    let title = 'Are you sure?';
    let text  = 'Removing button cannot be undone';
    let confirmButton = 'Yes, remove button';
    let cancelButton  = 'No, go back';

    try {
      const data = await this.getTranslatedWords(title, text, confirmButton, cancelButton);

      if (data.length === 4) {
        title = data[0]
        text  = data[1]
        confirmButton = data[2]
        cancelButton  = data[3]
      }

      swal({
          title: title,
          text: text,
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: confirmButton,
          cancelButtonText: cancelButton,
          confirmButtonClass: 'btn btn-lg btn-danger waves-effect waves-light',
          cancelButtonClass: 'btn btn-lg btn-transparent waves-effect waves-light m-l-10',
          buttonsStyling: false
      }).then(function () {
        let { buttonId } = buttonParent.querySelector('input.form-control').dataset;
        controller.buttonContainerTarget.querySelector(`a.bb-btn-link.${buttonId}`).remove();
        buttonParent.parentElement.remove();
        controller.updateLabel();
      }, function (dismiss) {

      })
    } catch (e) {}
  }

  updateLabel(){
    this.mainButtonGroupTargets.forEach((button, index) => {
      button.querySelector('label').innerText = `Button ${++index}`
    })
  }

  async getTranslatedWords(title, text, confirmButton, cancelButton) {
    const result = await Weglot.translate({
      words: [
        { t: 1, w: title },
        { t: 1, w: text },
        { t: 1, w: confirmButton },
        { t: 1, w: cancelButton },
      ],
      languageTo: Weglot.getCurrentLang(),
    });
    return result;
  }

}
