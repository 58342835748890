import { Controller } from "stimulus";

export default class extends Controller {
  static targets = []

  initialize() {
    this.logoUrl = document.querySelector("#logo-picker").getAttribute("data-default-file");
    this.logoSize = document.querySelector("#capture_email_logo_size").value;
    this.content = document.querySelector(".visual");
  }

  logoSourceUpdate(event) {
    const file = event.currentTarget.files[0];
    this.logoUrl = URL.createObjectURL(file);
    this.updatePreview();
  }

  logoSizeUpdate(event) {
    this.logoSize = event.currentTarget.value
    this.updatePreview();
  }

  logoPositionUpdate(event){
    event.stopPropagation();
    document.querySelectorAll('.btn-group .btn.btn-transparent').forEach(button => {
      const inputRadio = button.querySelector('input[type=radio]')
      if (button === event.currentTarget) {
        button.classList.add("active")
        inputRadio.setAttribute("checked", "checked")
      } else {
        button.classList.remove("active")
        inputRadio.removeAttribute("checked")
      }
    })
    this.updatePreview();
  }

  titleUpdate(event) {
    let title = event.currentTarget.value
    document.querySelector('#title-default').value = title
    this.content.querySelector('.bb-card-title').textContent = title
  }

  descriptionUpdate() {
    event.stopPropagation();
    let description = event.currentTarget.querySelector(`#snow-editor .ql-editor`).innerHTML
    document.querySelector('#gb-snow-editor .ql-editor').innerHTML = description
    this.content.querySelector('.bb-card-desc').setHTMLUnsafe(description)
  }

  emailPlaceholderUpdate(event) {
    this.content.querySelector('.bb-form-input').placeholder = event.currentTarget.value
  }

  buttonTextUpdate(event) {
    this.content.querySelector('#bb-btn').textContent = event.currentTarget.value
  }

  getLogoPosition () {
    if (document.querySelector("#capture_email_logo_position_left").checked) {
      return "left"
    } else if (document.querySelector("#capture_email_logo_position_right").checked) {
      return "right"
    }
    return "center"
  }

  updatePreview(){
    const logoElement = this.content.querySelector("#logo");
    this.logoPosition = this.getLogoPosition();

    logoElement.setAttribute("src", this.logoUrl);

    logoElement.setAttribute("style", `
      width: ${this.logoSize}%;
      margin-left: ${this.logoPosition === "left" ? 0 : "auto"};
      margin-right: ${this.logoPosition === "right" ? 0 : "auto"};
    `);
    }
}
