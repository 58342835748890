import { Controller } from "stimulus";

export default class extends Controller {

  connect() {
    let selectedButton = document.querySelector(".setup-card-btn.selected");
    if (selectedButton) {
      // Create a new click event
      const clickEvent = { currentTarget: selectedButton }
      this.setupRedirect(clickEvent)
    }
  }

  initialize() {
    this.content = document.querySelector(".visual");
    this.buttons = document.querySelectorAll(".setup-card-btn");
    this.setupTitle = document.querySelector(".setup-option-title");
    this.setupOptions = document.querySelectorAll(".ro-setup-option");
    this.visualContainers = document.querySelectorAll(".visual-container");
  }

  // Text Page
  titleUpdate() {
    let title = event.currentTarget.value
    document.querySelector('#title-default').value = title
    this.content.querySelector('.bb-card-title').textContent = title
  }

  descriptionUpdate() {
    event.stopPropagation();
    let description = event.currentTarget.querySelector(`#snow-editor .ql-editor`).innerHTML
    document.querySelector('#gb-snow-editor .ql-editor').innerHTML = description
    this.content.querySelector('.bb-card-desc').setHTMLUnsafe(description)
  }

  imagePost (event) {
    const file = event.currentTarget.files[0];
    document.querySelector('.bb-image-post img').setAttribute("src", URL.createObjectURL(file))
  }

  instagramPost (event) {
    document.querySelector('.bb-instagram-post').setHTMLUnsafe(event.currentTarget.value)
  }

  setupRedirect (event) {
    const button = event.currentTarget
    // Remove 'selected' class from all buttons
    this.buttons.forEach((button) => button.classList.remove("selected"));
    // Add 'selected' class to clicked button
    button.classList.add("selected");

    // Get the text of the clicked button
    const buttonText = button.textContent.trim();
    // Update the setup option title
    this.setupTitle.textContent = buttonText;

    // Remove 'ro-active' class from all setup options
    this.setupOptions.forEach((option) => option.classList.remove("ro-active"));

    // Remove 'visual-active' class from all visual containers
    this.visualContainers.forEach((visual) => visual.classList.remove("visual-active"));

    // Determine which section to activate
    const idName = buttonText.toLowerCase().replace(/\s+/g, "-"); // Convert text to lowercase and replace spaces with hyphens

    // Activate the corresponding setup option
    const selectedOption = document.getElementById(`ro-${idName}`);
    if (selectedOption) {
      selectedOption.classList.add("ro-active");
    }

    // Activate the corresponding visual container
    const selectedVisual = document.getElementById(`${idName}-visual`);
    if (selectedVisual) {
      selectedVisual.classList.add("visual-active");
    }
  }

}