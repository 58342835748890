import { Controller } from "stimulus";

export default class extends Controller {

  goBack () {
    window.history.back();
  }

  saveAndContinue(event){
    let element = document.querySelector('.ro-setup-option.ro-active')
    if (element)
      element.querySelector('.form-trigger').click();
    else
      document.querySelector('.form-trigger').click();
  }

}
